
import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, Dialog, Icon, IconButton } from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import firebase from "gatsby-plugin-firebase";



const HomeAppBar = (props) => {

    let [showSignOut, setShowSignOut] = useState(false)

    useEffect(()=>{
        firebase.auth().onAuthStateChanged((user)=>{
            if(user){
                setShowSignOut(true)
            }
            else{
                window.location.href = "/login"
            }
        })
    },[])

    return (
        <AppBar style={{ position: "sticky", display:"flex", flexDirection:"row", justifyContent:"space-between" }}>
            <div style={{display:"inherit", flexDirection:"row"}}>
            <IconButton href="/viewseasons">
                <HomeIcon style={{color:"white"}} />
            </IconButton>
            <h1>{props.title}</h1>
            </div>
            {
            showSignOut ? <Button style={{color:"white"}}
            onClick={()=>{
                firebase.auth().signOut()
                window.location.href = ""
            }}
            >Sign Out</Button> : null
            }
        </AppBar>
    )
}
export default HomeAppBar